import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useLocation } from "@reach/router"

import Header from "../../components/home/Header"

import MainBenefits from "../../components/home/MainBenefits"
import Overview2 from "../../components/home/Overview2"
import Feedback from "../../components/home/Feedback"
import Incentive from "../../components/home/Incentive"
import TestOrContact from "../../components/home/TestOrContact"
import HowTo3Steps from "../../components/home/HowTo3Steps"
import Box from "../../components/home/Box"
import Accordion from "../../components/AccordionBlock"
import PharmacyPartner from "../../components/home/PharmacyPartner"
import { Helmet } from "react-helmet"
import { deCH } from "../../lang/de-CH"

export default function Home() {
  const location = useLocation()

  let country = "CH"
  let lang = "de-CH"
  let txt = deCH

  return (
    <Layout location={country} path={location.pathname} language={lang}> 
      <Seo
        title={txt.SEO.title}
        description={txt.SEO.description}
        lang={lang}
      />
      <Helmet>
        <link
          rel="alternate"
          hreflang="de-DE"
          href="https://dorygo.com/de-de/"
        />
        <link
          rel="canonical"
          hreflang="de-CH"
          href="https://dorygo.ch/ch-de/"
        />
        <link
          rel="alternate"
          hreflang="fr-CH"
          href="https://dorygo.com/ch-fr/"
        />
      </Helmet>
      <Header txt={txt.home.Header} country={country} language={lang}/>
      <Incentive txt={txt.home.Incentive}/>
      <Overview2 txt={txt.home.Overview} country={country} language={lang}/>
      <Box txt={txt.home.BoxExplained} />
      <Feedback txt={txt.home.Quotes} />

      <MainBenefits txt={txt.home.MainBenefits} />
      <PharmacyPartner txt={txt.home.PharmacyPartner} country={country}/>
      <HowTo3Steps txt={txt.home.HowTo} country={country} language={lang}/>
      <TestOrContact txt={txt.home.TestOrContact}/>
      <Accordion input ={txt.home.FAQ}/>  
      
    </Layout>
  )
}




